// Hero
.hero {
  background-color: #4d8dab;
  margin: 0;
  overflow: hidden;
  padding: 6rem 2rem;
  padding: 0 $padding-default;
  position: relative;
}

.hero__video {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  max-height: none;
}

@supports (object-fit: cover) {
  .hero__video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.hero__contents {
  @include breakpoint(medium) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 60rem;
  }
}

.hero__text {
  background-color: rgba(white, 0.75);
  backdrop-filter: blur(2px);
  padding: $padding-default;
  position: relative;

  @include breakpoint(medium) {
    margin-top: calc(2rem + 8vw);
    margin-bottom: calc(2rem + 8vw);
    width: 50%;
  }

  @include breakpoint(large) {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
}

.hero__heading {
  color: darken($color-primary, 5%);
  font-size: $font-size-extra-large;
  font-weight: 300;
  line-height: 1.2;
}

.hero__screenshot {
  margin: 3em auto 0;
  max-width: 20rem;
  padding: 0 $padding-default;
  position: relative;
  @include breakpoint(medium) {
    margin-top: 0;
    max-width: none;
    padding-top: 4rem;
    width: 50%;
  }
}

.hero__screenshot-container {
  margin-top: 0;
  position: relative;
  height: 0;
  padding-top: 120%;

  > img {
    animation-name: slideup;
    animation-delay: 0.5s;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    filter: drop-shadow(-10px 5px 10px rgba(black, 0.5));
    margin-top: 0;
    position: absolute;
    top: 3rem;
    left: 1rem;
    width: 70%;
    height: auto;
  }
}

.hero__screenshot-slideshow {
  animation-name: slideup;
  animation-duration: 0.5s;
  animation-delay: 0.4s;
  animation-fill-mode: both;
  filter: drop-shadow(-10px 5px 10px rgba(black, 0.5));
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;

  > img {
    animation-name: slideshow;
    animation-duration: 40s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    margin-top: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    //transition: opacity 0.2s linear;
    backface-visibility: hidden;

    &:nth-of-type(1) {
      animation-delay: 30s;
    }
    &:nth-of-type(2) {
      animation-delay: 20s;
    }
    &:nth-of-type(3) {
      animation-delay: 10s;
    }
    &:nth-of-type(4) {
      animation-delay: 0s;
    }
  }
}
