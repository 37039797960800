// Header
.site-header {
  background-color: $color-section-background;
  margin-top: 0; // fix margin from browsersync injected scripts
}

.site-header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $layout-max-width;
  padding: $padding-default;
}

.site-header__title {
  flex-grow: 1;
  font-size: $font-size-logo;
  font-weight: 600;
}

.site-header__link {
  color: $color-primary;
  text-decoration: none;
}

.site-header__logo {
  display: inline-block;
  margin-top: 2px;
  vertical-align: top;
}

.site-header__nav {
  margin-top: 0;
  
  a {
    color: $color-primary;
    margin-left: 1em;
    text-decoration: none;
    
    &:hover {
      color: $color-link;
    }
  }
}