/* Colors */

$color-primary: #00bc8c;

$color-success: #33c965;
$color-info: #157efb;
$color-warning: #f39c12;
$color-danger: #e74c3c;

$color-text: #333333;
$color-text-reverse: #ffffff;
$color-text-soften: #777777;
$color-text-tabs: #929292;
$color-link: #2683c9;

$color-body-background: #ffffff;
$color-section-background: #efeff4;
$color-header-background: blue;
$color-border: #c8c7cc;
$color-navbar-background: $color-primary;
$color-tabbar-background: #f8f8f9;

$color-button-background: #ffffff;
$color-button-background-active: mix($color-section-background, black, 90%);

/* Typography */
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", serif;

$font-size-default-px: 16;
$font-size-default: (16 / $font-size-default-px) + rem;
$font-size-subtle: (14 / $font-size-default-px) + rem;
$font-size-small: (12 / $font-size-default-px) + rem;
$font-size-extra-small: (8 / $font-size-default-px) + rem;
$font-size-large: (20 / $font-size-default-px) + rem;
$font-size-extra-large: (36 / $font-size-default-px) + rem;

$font-size-logo: (24 / $font-size-default-px) + rem;

$font-size-list-group-item: $font-size-large;

$line-height-default: 1.5;

$icon-size-default: 20px;

/* Layout */
$layout-max-width: 60rem;

$layout-list-group-min-height: 48px;

/* Margins and padding */
$margin-default: 16px;
$margin-small: 8px;
$margin-extra-small: 4px;
$margin-large: 24px;
$margin-extra-large: 32px;

$padding-default: 16px;
$padding-small: 8px;
$padding-extra-small: 4px;
$padding-large: 24px;
$padding-extra-large: 32px;

$padding-list: 40px;

/* Forms */
$checkbox-toggle-width: 60px;
$checkbox-toggle-height: 34px;
$checkbox-toggle-switch-size: 30px;

/* Effects */
$box-shadow-default: 1px 1px 2px rgba(black, 0.2);
$box-shadow-large: 1px 1px 4px rgba(black, 0.2);

$text-shadow-default: 1px 1px 5px rgba(black, 0.1);

$border-radius-default: 2px;
$border-radius-large: 4px;


/* Mixins */
@mixin input-placeholder($color, $opacity: 1) {
  &::-webkit-input-placeholder {
    color: $color;
    opacity: $opacity;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: $opacity;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: $opacity;
  }
  &:-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }
}

@mixin action-text {
  appearance: none;
  background: none;
  border: none;
  color: $color-text-reverse;
  flex-grow: 1;
  display: inline-block;
  //font-size: $font-size-large;
  font-weight: 400;
  line-height: $layout-app-navbar-height;
  margin: 0;
  padding: 0;
  text-align: inherit;
  text-decoration: inherit;
  height: $layout-app-navbar-height;
  &:active {
    opacity: 0.5;
  }
}

@mixin nowrap {
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  white-space: nowrap;
}

@mixin hairline-border-width {

}

@mixin hairline-border-table-top {
  @media (-webkit-min-device-pixel-ratio: 2) {
    border-top: none;
    position: relative;

    > th,
    > td {
      position: relative;

      &:before {
        border-top: 0.5px solid $color-border;
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
    //border-width: 0.5px; // TODO: detect browser support for this and use dynamic "hairlines" class for styling instead of media query
  }
}

@mixin hairline-border-table-bottom {
  @media (-webkit-min-device-pixel-ratio: 2) {
    border-bottom: none;
    position: relative;

    > th,
    > td {
      position: relative;

      &:before {
        border-bottom: 0.5px solid $color-border;
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
    //border-width: 0.5px; // TODO: detect browser support for this and use dynamic "hairlines" class for styling instead of media query
  }
}

// Media query mixins
@mixin breakpoint( $point )  {
  @if $point == extra-large {
    @media ( min-width: 86em ) { @content; } // 1376 px e.g. modest laptop
  }
  @else if $point == large {
    @media ( min-width: 64em ) { @content; } // 1024 px e.g. iPad landscape
  }
  @else if $point == medium {
    @media ( min-width: 48em ) { @content; } // 768 px e.g. iPad portrait
  }
  @else if $point == small {
    @media ( min-width: 32em )  { @content; } // 512px
  }
}

/* Functions */

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

/* Animations */
@keyframes slideshow {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  98% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
};

@keyframes slideup {
  0% {
    transform: translateY(10rem);
  }
  100% {
    transform: translateY(0);
  }
}