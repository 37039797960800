// Reset margins/padding
* {
  appearance: none;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

// Global/generic styles
html {
  box-sizing: border-box;
  font-size: 100%;
}

body {
  background-color: $color-body-background;
  color: $color-text;
  font-family: $font-family-sans-serif;
  margin: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}

* + * {
  margin-top: 1em;
}

// Responsive media
img, video, audio, iframe {
  max-width: 100%;
  height: auto;
}

// Old IE HTML5 Fix
main {
  display: block;
}
video {
  display: block;
}