body {
  color: $color-text;
  font-family: $font-family-sans-serif;
  font-size: $font-size-default;
  line-height: $line-height-default;
}

h1 {
  font-size: $font-size-extra-large;
  font-weight: 300;
}

pre {
  background-color: mix($color-text-soften, $color-text-reverse, 30%);
  color: $color-text;
  overflow-x: auto;
  padding: 0.5em;
  white-space: pre;
}

code {
  background-color: mix($color-text-soften, $color-text-reverse, 30%);
  border-radius: $border-radius-default;
  color: $color-text;
  padding-right: 0.25em;
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  padding-left: $padding-list;
}

ol {
  list-style: decimal;
  padding-left: $padding-list;
}

.text-subtle {
  color: $color-text-soften;
  font-size: $font-size-subtle;
}
