.site-footer {
  background-color: $color-border;
  font-size: $font-size-small;
  margin-top: 4rem;
  padding: $padding-default 0;
  text-align: center;
}

.site-footer__container {
  margin-left: auto;
  margin-right: auto;
  max-width: $layout-max-width;
  padding: 0 $padding-default;
}

.site-footer__downloads {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-default;
  
  > a {
    margin-top: 0;
    margin-left: 0.5em;
  }
}

.site-footer__nav {
  a {
    color: $color-text;
  }
}

.site-footer__copyright {
  margin-left: auto;
  margin-right: auto;
  max-width: $layout-max-width;
  padding: 0 $padding-default;
}