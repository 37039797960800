// Intro
.intro {
  font-size: $font-size-large;
  font-weight: 200;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: $layout-max-width / 1.5;
  padding: 0 $padding-default;
}
.intro__heading {
  color: $color-primary;
  font-size: $font-size-extra-large;
  font-weight: 300;
}
.intro__cta-link {
  color: $color-link;
  font-weight: 400;
  text-decoration: none;
  
  &:hover {
    color: lighten($color-link, 10%);
  }
}

// Features
.features {
  background-color: $color-primary;
  color: $color-text-reverse;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.features__container {
  margin-left: auto;
  margin-right: auto;
  max-width: $layout-max-width / 1.5;
  padding: $padding-default * 2 $padding-default;
}
.features__heading {
  font-size: $font-size-extra-large;
  font-weight: 300;
}
.features__list {
  list-style: none;
  padding-left: 0;
  
  > li + li {
    margin-top: 2em;
  }
  
  > li {
    align-items: flex-start;
    display: flex;
    
    > svg.icon {
      display: block;
      flex-shrink: 0;
      margin-right: $padding-default;
      //width: 40px;
      //height: 40px;
    }
    
    > div {
      margin-top: 0;
    }
  }
}

// Video
.preview-video {
  margin-top: 2rem;
}
.preview-video__container {
  margin-left: auto;
  margin-right: auto;
  max-width: $layout-max-width / 1.5;
  padding: $padding-default * 2 $padding-default;
}
.preview-video__heading {
  color: $color-primary;
  font-size: $font-size-extra-large;
  font-weight: 300;
}
.preview-video__description {}
.preview-video__frame {
  background-color: black;
  border: 10px solid black;
  border-radius: 25px;
  padding: 30px 0 60px;
  margin-left: auto;
  margin-right: auto;
  width: 360px;
  max-width: 100%;
}
.preview-video__aspect-ratio-9x16 {
  position: relative;
  height: 0;
  padding-top: 177.77778%;
}
.preview-video__embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}